<template>
  <div>
    <Spinner v-if="showSpinner"></Spinner>
    <div :class="{ 'blur-background': showSpinner }">
      <PostViewer v-if="article && article._id" :article="article"></PostViewer>
      <b-card
        v-if="allParts.length > 0"
        class="mt-2 mt-md-4 mb-4 mx-2 mx-md-4"
        border-variant="success"
      >
        <b-card-header header-bg-variant="light" align="left"
          ><b>All parts of this story</b></b-card-header
        >
        <b-card-body>
          <div v-for="(part, index) in allParts" :key="part._id">
            <ul class="pr-4 pt-3" style="text-align: left">
              <li class="part-color">
                <u @click="redirectToPost(part._id)">{{ part.title }}</u>
              </li>
            </ul>
            <hr class="mb-0" v-if="index !== allParts.length - 1" />
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BCard, BCardBody, BCardHeader } from "bootstrap-vue";

export default {
  components: {
    PostViewer: () => import(/* webpackPrefetch: true */ "./PostViewer.vue"),
    Spinner: () =>
      import(/* webpackPrefetch: true */ "../components/Spinner.vue"),
    BCard,
    BCardBody,
    BCardHeader,
  },

  data() {
    return {
      article: {},
      allParts: [],
      showSpinner: false,
    };
  },

  mounted() {
    this.articleId = this.$route.params.id;

    this.getArticle();
  },

  methods: {
    ...mapActions(["fetchArticle", "getAllParts", "signOut"]),

    redirectToPost(id) {
      this.$router.push({ name: "FullPost", params: { id } });
      this.$router.go();
    },

    async getArticle() {
      this.showSpinner = true;
      try {
        this.article = await this.fetchArticle({ id: this.articleId });

        let allParts = [];

        if (this.article?.isPilot) {
          allParts = await this.getAllParts({ id: this.articleId });
        } else if (this.article?.parentId) {
          allParts = await this.getAllParts({ id: this.article.parentId });
        }

        this.allParts = allParts.filter((item) => item._id !== this.articleId);
      } catch (e) {
        console.error(e);
      } finally {
        this.showSpinner = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-background {
  opacity: 0.25;
}
.part-color {
  cursor: pointer;
  font-weight: 700;
}
.part-color:hover {
  color: #2c3e50;
  opacity: 0.7;
}
</style>
